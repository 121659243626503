<template>
  <div>
    <div class="role-list">
      <div class="role-search">
        <el-input placeholder="搜索" clearable v-model="roleVal" @change="searchRole">
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <div class="role-list-items">
        <div
          class="role-list-item"
          v-for="item in roleData"
          :key="item.id"
          :class="{ selected: item.id === selected }"
          @click="checkedItem(item.id, item.code)"
        >
          <div class="item-box">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="role-table">
      <el-table
        :data="newTabelData"
        :span-method="deviceSpanMethod"
        ref="table"
        stripe
        border
        style="width: 100%"
        highlight-current-row
        v-loading="loading"
        :header-cell-style="{ color: '#212A33', backgroundColor: '#fafafa' }"
        height="calc(92vh - 1rem)"
      >
        <el-table-column prop="firstName" label="目录" width="300"> </el-table-column>
        <el-table-column prop="secondName" label="菜单" width="300">
          <template slot-scope="scope">
            <span
              ><el-checkbox-group v-model="checkedBtnArr">
                <el-checkbox
                  :disabled="userCode == 'sys_tenant_manager_role'"
                  v-for="(item, index) in scope.row.secondMenuArr"
                  :label="item.id"
                  :key="item.id"
                  v-model="checkedMenuVla"
                  @change="checkMenuChange($event, scope.row.btnArr, scope.row.id, scope.row)"
                  >{{ item.title }}</el-checkbox
                >
              </el-checkbox-group></span
            >
          </template>
        </el-table-column>
        <el-table-column prop="btnArr" label="权限">
          <template slot-scope="scope">
            <el-checkbox-group
              v-model="checkedBtnArr"
              @change="checkGroupChange($event, scope.row.btnArr, scope.row.id, scope.row)"
            >
              <el-checkbox
                :disabled="userCode == 'sys_tenant_manager_role'"
                v-for="(item, index) in scope.row.btnArr"
                :label="item.id"
                :key="item.id"
                >{{ item.title }}</el-checkbox
              >
            </el-checkbox-group>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-checkbox
              :disabled="userCode == 'sys_tenant_manager_role'"
              :indeterminate="scope.row.isIndeterminate"
              v-model="scope.row.checkAll"
              @change="handleCheckAllChange($event, scope.row.btnArr, scope.row.id, scope.row)"
              >全选</el-checkbox
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { getAllRole } from '@/api/modular/system/roleManage'

import templateTitle from '@/components/templateTitle.vue'

import { _proTree } from '@/api/modular/supervision/projectMag/tab3'
import { sysRoleOwnMenuApplication } from '@/api/modular/system/tenantManage' //更改为公司拥有菜单接口
import { SysMenuTreeForGrant } from '@/api/modular/system/menuManage' //同步超管租户授权接口

import { sysRoleGrantMenu } from '@/api/modular/system/roleManage'
export default {
  name: 'authorMagPc',
  data() {
    return {
      checkedMenuVla: null, //缓存当前点击的值
      userCode: '',
      // menuRadio: 'web菜单', // web菜单 app菜单
      selected: null, // 默认未选中

      drawer: false,
      roleEntity: {}, //角色实体

      tableData: [],
      newTabelData: [],
      checkedBtnArr: [],
      isCheckLevel3Children: false,
      firstMenuArr: [], // 一级级功能id存储
      checkedFirstArr: [], //一级菜单勾选提交
      secondMenuArr: [], // 二级功能id存储
      checkedSecondArr: [], //二级菜单勾选提交
      checked: false,

      isPCCheckAll: false,
      isAppCheckAll: false,
      isPCIndeterminate: false,
      isAppIndeterminate: false,
      isPcArr: [], // 初始化pc全新
      isAppArr: [], // 初始化app全新

      isIndeterminate: false,
      roleData: [], //授权角色page
      roleVal: null, //授权角色数据

      treeArray: [], // 用于匹配id赋值isApp、isPC
      flatArray: [], // 用于匹配id赋值isApp、isPC

      tableData: [],
      searchObj: {
        name: ''
      }, //搜索字段
      loading: false //是显示加载
    }
  },
  computed: {
    checkAll: {
      get() {
        return this.newTabelData.every(item => item.checkAll)
      },
      set(value) {
        this.newTabelData.forEach(item => {
          item.checkAll = value
        })
      }
    }
  },

  components: {
    templateTitle
  },
  watch: {
    userCode: {
      handler(newValue, oldValue) {
        console.log(newValue, oldValue, "newValue, oldValue'------")
        if (newValue) {
          this.$emit('getCode', newValue)
        }
      }
    }
  },
  created() {},
  mounted() {
    this.getRoleList() //获取人员列表
  },
  methods: {
    getRoleList(roleName) {
      let param = {
        name: roleName ?? ''
      }
      getAllRole(param).then(res => {
        if (res.code == 200) {
          this.roleData = res.data
          if (this.roleData.length > 0) {
            this.checkedItem(this.roleData[0].id, this.roleData[0].code)
          }
        }
      })
    },
    // 搜索角色
    searchRole(value) {
      this.getRoleList(value)
    },
    // 选择
    checkedItem(id, code) {
      this.selected = id
      this.userCode = code
      this.initTreeData(id)
    },

    // esc和点击旁白
    clearDrawerContent(done) {
      this.roleEntity = {}
      done()
    },
    // 取消或者是确认
    btnClose() {
      this.roleEntity = {}
      this.drawer = false
    },
    logCheckedArr() {},
    checkAndAddIds(arr1, arr2) {
      var newArr = []

      // 遍历 arr1 数组
      for (var i = 0; i < arr1.length; i++) {
        // var id = arr1[i].id
        var id = arr1[i]
        var exists = false

        // 遍历 arr2 数组
        for (var j = 0; j < arr2.length; j++) {
          // 遍历 arr2 数组的子节点
          function traverse(node) {
            if (node.id === id) {
              exists = true
              return
            }
            if (node.children) {
              for (var k = 0; k < node.children.length; k++) {
                traverse(node.children[k])
              }
            }
          }

          traverse(arr2[j])
          if (exists) {
            newArr.push(id)
            break
          }
        }
      }

      return newArr
    },
    handleSubmit() {
      this.loading = true
      let result = this.checkAndAddIds(this.checkedBtnArr, this.tableData)
      let uniqueArray = [...new Set(result)]
      // PC PC = 1
      // APP APP = 2
      sysRoleGrantMenu({
        id: this.selected,
        grantMenuIdList: uniqueArray,
        type: 1
      })
        .then(res => {
          if (res.success) {
            this.$message.success('授权成功')
            this.loading = false
            this.$emit('ok')
            this.btnClose()
          } else {
            this.$message.error('授权失败：' + res.message)
            this.loading = false
          }
        })
        .finally(res => {
          this.loading = false
        })
    },
    filterDuplicates(arr1, arr2) {
      let dealArr = arr1.map(ele => {
        return ele.id
      })
      const uniqueArray = arr2.filter(item => !dealArr.includes(item))
      return uniqueArray
    },
    //  checkall
    handleCheckAllChange(value, btnarr, id, row) {
      // console.log(value, 'value44444')
      // this.checkedBtnArr = val ? cityOptions : []
      // this.isIndeterminate = false
      // console.log(id, 'let checkAll = this.checkIdsExist(value, btnarr)') //416275052470341
      // let checkAll = this.checkIdsExist(value, btnarr)

      this.newTabelData.forEach(item => {
        if (item.id == id) {
          // item.checkAll = checkAll
          item.isIndeterminate = false
        }
      })
      if (value) {
        let dealArr = btnarr.map(ele => {
          return ele.id
        })
        // let nn = [...this.checkedBtnArr, ...dealArr] //
        let nn = [...this.checkedBtnArr, ...dealArr, row.id] //全选加入菜单id
        this.checkedBtnArr = [...new Set(nn)]
        console.log(this.checkedBtnArr, '全选时全部的数据', row.id)
      } else {
        const filterArr = [...btnarr, { id: row.id }]
        console.log(filterArr, 'filterArr')
        let nn = this.filteredArr(this.checkedBtnArr, filterArr)
        // console.log(nn, 'nn222222')
        this.checkedBtnArr = nn
        console.log(this.checkedBtnArr, '菲菲菲菲菲全选++++++', row.id)
      }
    },
    checkMenuChange(value, btnarr, id, row) {
      this.newTabelData.forEach(item => {
        if (item.id == id) {
          item.checkAll = value //点击菜单id 选中全选标识
          item.isIndeterminate = false
        }
      })
      if (value) {
        let dealArr = btnarr.map(ele => {
          return ele.id
        })
        // let nn = [...this.checkedBtnArr, ...dealArr] //
        let nn = [...this.checkedBtnArr, ...dealArr, row.id] //全选加入菜单id
        this.checkedBtnArr = [...new Set(nn)]
        console.log(this.checkedBtnArr, '全选时全部的数据', row.id)
      } else {
        const filterArr = [...btnarr, { id: row.id }]
        console.log(filterArr, 'filterArr')
        let nn = this.filteredArr(this.checkedBtnArr, filterArr)
        // console.log(nn, 'nn222222')
        this.checkedBtnArr = nn
        console.log(this.checkedBtnArr, '菲菲菲菲菲全选++++++', row.id)
      }
    },
    // btn check
    checkGroupChange(value, btnarr, id, row) {
      // console.log(value, 'value2222')
      // let checkedCount = value.length
      // this.checkAll = checkedCount === this.cities.length
      // this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length
      // console.log(value, btnarr, 'value, btnarr')
      let checkedCount = this.findDuplicates(value, btnarr)
      let checkAll = this.checkIdsExist(value, btnarr)
      this.newTabelData.forEach(item => {
        if (item.id == id) {
          // 判断是否全选
          item.checkAll = checkAll
          item.isIndeterminate = checkedCount > 0 && checkedCount < btnarr.length
          console.log(item, 'item222')
          // 回选父级菜单
          this.checkedBtnArr.push(item.id)
          this.checkedBtnArr = [...new Set(this.checkedBtnArr)]
          console.log(this.checkedBtnArr, 'this.checkedBtnArr')
          // 全不选之后，去掉父级选中，全选标识
          if (item.checkAll == false && item.isIndeterminate == false) {
            const filterArr = [...btnarr, { id: row.id }]
            let nn = this.filteredArr(this.checkedBtnArr, filterArr)
            this.checkedBtnArr = nn
          }
        }
      })
    },
    btnInitFormatter(row, column, cellValue, index) {
      // console.log(111111111, 'row')
    },
    // 过滤重复的id
    filteredArr(arr1, arr2) {
      let dealArr = arr2.map(ele => {
        return ele.id
      })
      return arr1.filter(id => !dealArr.includes(id))
    },
    // 检验是否存在
    checkIdsExist(arr1, arr2) {
      let abc = arr2.map(ele => {
        return ele.id
      })
      return abc.every(id => arr1.includes(id))
    },
    // 获取重复的长度
    findDuplicates(array1, array2) {
      const result = array2.filter(item => array1.includes(item.id))
      return result.length
    },
    initTreeData(roleId) {
      this.loading = true
      SysMenuTreeForGrant({
        Application: 'manage',
        isPC: true
      }).then(res => {
        if (res.success) {
          this.tableData = res.data
          this.treeArray = res.data
          console.log(this.tableData, 'this.tableData0000000')
          if (this.tableData.length) {
            // 树形存在再拉取拥有权限
            sysRoleOwnMenuApplication({
              type: 1,
              id: roleId
            }).then(res => {
              if (res.success) {
                // 兼容旧版本循环应用展示、保留此方法便于后期拓展
                let filterArr = res.data
                  .filter(obj => obj && obj.application && obj.application === 'manage')
                  .map(obj => obj.menuIds)[0]
                // 过滤id、兼容空菜单的情况，无checkId的情况
                if (filterArr) {
                  this.checkedBtnArr = filterArr.length
                    ? filterArr.map(ele => {
                        return ele.id
                      })
                    : []
                } else {
                  this.checkedBtnArr = []
                }
                console.log(this.checkedBtnArr, ' this.checkedBtnArr1111111111')
                this.flatArray = res.data.length ? res.data[0].menuIds : []
                // console.log(this.flatArray, 'this.flatArray')
                // 树形数据拉取完成之后再处理数据
                this.dealTableData()
              }
            })
          }
        }
      })
    },
    logCheckedArr() {
      console.log('+++++++选择', this.checkedBtnArr)
    },
    getTreeDeep(tree) {
      tree.forEach(item => {
        let maxDeep = 0
        console.log(item, '==========getTreeDeep========')

        if (item.children?.length > 0) {

            item.children.forEach(ele=>{
              // console.log(ele,"===第二层===");
              if(ele.children?.length > 0){
                maxDeep += ele.children.length
              }else{
                maxDeep = item.children.length
              }
            })

            console.log(maxDeep,"=======maxDeep====");
        }
      })
    },
    dealTableData() {
      console.log(this.tableData, '---------------获取原始数据---------------')

      // this.getTreeDeep(this.tableData)

      // return
      let StagArr = []
      let _this = this
      this.tableData.forEach(firstData => {
        // console.log(firstData, 'firstDatatopppppppppppppppppp')
        if (firstData.children.length > 0) {
          // console.log(firstData.children, 'firstData.children11111')
          let l1 = firstData.children.length
          // 判断二级菜单下的children是否存在
          if (firstData.children[0].type == 2) {
            let data = {
              firstName: firstData.title,
              firstMenuArr: [{ ...firstData }],
              secondName: '',
              // secondMenuArr: [],
              secondMenuArr: [{ ...this.matchArrayId(firstData, this.flatArray) }],
              btnArr: firstData.children,
              id: firstData.id,
              checkAll: false,
              isIndeterminate: false,
              deviceNum: 0, // 要合并的“一级菜单”
              oneNum: 0 // 仅有一级的情况
            }
            // console.log(data, '处理之后的每一项data')
            StagArr.push(data)
          }
          firstData.children.forEach((secondData, index) => {
            // console.log(secondData, 'secondData22222222')
            if (secondData.children.length) {
              let data = {
                firstName: firstData.title,
                firstMenuArr: [{ ...firstData }],
                secondName: secondData.title,
                // secondMenuArr: [{ ...secondData }],
                secondMenuArr: [{ ...this.matchArrayId(secondData, this.flatArray) }],
                btnArr: secondData.children.length ? secondData.children : [],
                id: secondData.id,
                checkAll: false,
                isIndeterminate: false,
                deviceNum: 0 // 要合并的“一级菜单”
              }
              if (index === 0) {
                data.deviceNum = l1 // 改变“一级菜单”数值 让每一个传感器的第一个对象的deviceNum有值 其他为0（比如图中第一个菜单deviceNum为4，下面三个均为0）
              } else {
                data.deviceNum = 0
              }
              // console.log(data, '处理之后的每一项data')
              StagArr.push(data)
            }
          })
        } else {
          {
            // console.log(firstData, '当第二级children为空时11111111111111111111111111111111111111111111111111111')
            let data = {
              firstName: firstData.title,
              firstMenuArr: [{ ...firstData }],
              secondName: '',
              // secondMenuArr: [],
              secondMenuArr: [{ ...this.matchArrayId(firstData, this.flatArray) }],
              btnArr: [],
              id: firstData.id,
              checkAll: false,
              isIndeterminate: false,
              deviceNum: 0, // 要合并的“一级菜单”
              oneNum: 0 // 仅有一级的情况
            }
            StagArr.push(data)
          }
        }
      })
      // console.log(StagArr, '_this.StagArrchulidoddddddddddddd')

      // this.newTabelData = StagArr
      this.newTabelData = StagArr
      this.btnCheckAll(this.newTabelData) // 回显全选
      console.log(this.newTabelData, 'this.newTabelData回显全选之后的2')
      this.loading = false
    },
    btnCheckAll(tableData) {
      tableData.forEach(item => {
        let checkedCount = this.findDuplicates(this.checkedBtnArr, item.btnArr)
        let checkAll = this.checkIdsExist(this.checkedBtnArr, item.btnArr)
        item.checkAll = checkAll
        item.isIndeterminate = checkedCount > 0 && checkedCount < item.btnArr.length
      })
    },
    deviceSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (row.deviceNum != 0) {
          return [row.deviceNum, 1]
        } else {
          // return [0, 0];
          if (row?.oneNum == 0) {
          } else {
            return [0, 0]
          }
        }
      }
    },

    // 匹配相同id
    matchArrayId(obj, array2) {
      // console.log(obj, array2, '匹配相同id')
      const matchedItem = array2.find(item2 => item2.id == obj.id)
      if (matchedItem) {
        obj.isApp = matchedItem.isApp
        obj.isPC = matchedItem.isPC
      }
      return obj
    }
  }
}
</script>
<style lang="scss" scoped>
.role-list {
  width: 300px;
  height: auto;
  max-height: calc(92vh - 1rem);
  border-right: 1px solid #ccc;
  margin-right: 5px;
  .role-search {
    width: 98%;
    margin-bottom: 5px;
    // padding: 2% 1%;
  }
  .role-list-items {
    height: auto;
    max-height: calc(100% - 45px);
    overflow-y: auto;

    .role-list-item {
      width: 98%;
      height: 50px;
      border: 1px solid #e5e8ef;
      margin-bottom: 5px;
      padding: 2% 4%;
      max-height: 750px;
      overflow-y: auto;
      // checklist
      .item-box {
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #010b1f;
        font-size: 15px;
      }
    }
  }
}
.role-table {
  width: calc(100% - 300px);
}
// 选中时更改背景色
.selected {
  background-color: #ecf7ff;
}
</style>
